import React, { Fragment } from "react";

import classes from "./Noda.module.css";

function Noda() {
  return (
    <Fragment>
      <video
        id="vid"
        className={classes.video}
        playsInline
        autoPlay
        muted
        loop
        disablePictureInPicture
        preload="true"
        src="noda_prosjekter.mov"
        type="video/mp4"
      ></video>
      <div className={classes.text}>I dette alternative designkonseptet kan brukeren bla gjennom ulike prosjekter som er knyttet til NODA. Prosjektene skal vise nordnorsk design og arkitektur som kan inspirere og vise lokal stolthet.</div>
    </Fragment>
  );
}

export default Noda;
