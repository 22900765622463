import React, { Fragment, useEffect } from "react";

import ProjectList from "../projects/ProjectList";

import projects from "../projects/projects.json";

import Footer from "../layout/Footer";

import classes from "./Home.module.css";

function Home() {
  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    window.scrollTo(0, parseInt(scrollPosition));
    sessionStorage.removeItem("scrollPosition");
  }, []);

  return (
    <Fragment>
      <div className={classes.header}>
        Brukeropplevelse (UX) / Grafisk Design / Interaksjonsdesign / Animasjon / Spilldesign / Nysgjerrighet / Utvikling / Frontend
      </div>
      <ProjectList projects={projects} />
      <Footer />
    </Fragment>
  );
}

export default Home;
