import React from "react";

import classes from "./Footer.module.css";

function Footer() {
  return (
    <div className={classes.contact}>
      <b>Stian Remvik</b> /{" "}
      <a className={classes.link} href="mailto:kontakt@stianremvik.com">kontakt@stianremvik.com</a> /{" "}
      <a className={classes.link} href="https://www.instagram.com/stianrtfmvik/">Instagram</a> /{" "}
      <a className={classes.link} href="https://www.linkedin.com/in/stian-remvik-7939201b2/">Linkedin</a>
    </div>
  );
}

export default Footer;