import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import classes from "./ProjectItem.module.css";

function ProjectItem(props) {
  function handleScrollPos() {
    sessionStorage.setItem("scrollPosition", window.pageYOffset);
  }

  return (
    <Fragment>
      <li>
        <div className={classes.wrapper}>
          <Link
            className={classes.link}
            // eslint-disable-next-line
            to={"/project" + "-" + props.id}
            onClick={handleScrollPos}
          >
            <video
              id="vid"
              className={classes.video}
              playsInline
              autoPlay
              muted
              loop
              disablePictureInPicture
              preload="true"
              src={props.video}
              type="video/mp4"
            ></video>
            <div className={classes.title}>{props.title}</div>
          </Link>
        </div>
      </li>
    </Fragment>
  );
}

export default ProjectItem;
