import React, { Fragment } from "react";

import ProjectItem from "./ProjectItem";

import classes from "./ProjectList.module.css";

function ProjectList(props) {
  return (
    <Fragment>
      <ul className={classes.list}>
        <div className={classes.grid_wrapper}>
          {props.projects.map((project) => (
            <ProjectItem
              key={project.id}
              id={project.id}
              title={project.title}
              video={project.video_short}
              hasAudio={project.hasAudio}
              preamble={project.preamble}
              text={project.text}
              image={project.image}
              deliverables={project.deliverables}
            />
          ))}
        </div>
      </ul>
    </Fragment>
  );
}

export default ProjectList;
