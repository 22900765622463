import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import classes from "./Project.module.css";

import Footer from "../layout/Footer";
import GumGun from "../pages/GumGun";
import Noda from "./Noda";

function Project(props) {
  const [isMuted, setMuted] = useState(true);
  const [hasControls, setHasControls] = useState(false);
  window.scrollTo(0, 0);

  function handleAudio() {
    if (isMuted) {
      let video = document.getElementById("vid");
      video.muted = false;
      let image = document.getElementById("audio_on_off");
      image.src = "audio_on.svg";
      setMuted(false);
    } else {
      let video = document.getElementById("vid");
      video.muted = true;
      let image = document.getElementById("audio_on_off");
      image.src = "audio_off.svg";
      setMuted(true);
    }
  }

  function handleControls() {
    if (hasControls) {
      let video = document.getElementById("vid");
      video.controls = false;
      document.getElementById("kontrollerknapp").innerHTML =
        "Vis videokontroller";
      setHasControls(false);
    } else {
      let video = document.getElementById("vid");
      video.controls = true;
      document.getElementById("kontrollerknapp").innerHTML =
        "Skjul videokontroller";
      setHasControls(true);
    }
  }

  return (
    <Fragment>
      
      <div className={classes.page_wrapper}>  
        <Link to="/">
          <button className={classes.close_button}>
              <img id="close_button" src="closeCross.svg" alt="close button"></img>
          </button>
        </Link>
        <div className={classes.wrapper}>
          <div className={classes.video_wrapper}>
            {
              <video
                id="vid"
                className={classes.video}
                playsInline
                autoPlay
                muted
                loop
                disablePictureInPicture
                preload="true"
                src={props.video}
                type="video/mp4"
              ></video>
            }
            <button
              className={classes.button_controls}
              id="kontrollerknapp"
              onClick={handleControls}
            >
              Vis videokontroller
            </button>
          </div>

          {props.hasAudio && (
            <button className={classes.audio_button} onClick={handleAudio}>
              <img id="audio_on_off" src="audio_off.svg" alt="audio on/off button"></img>
            </button>
          )}
          <div className={classes.title}>{props.title}</div>
          <div className={classes.deliverables}>{props.deliverables}</div>

          {props.title === "GumGun - Mobilspill" && (
            <a
              href="https://itunes.apple.com/no/app/gumgun/id1440763582?l=nb&mt=8"
            >
              <button className={classes.call_to_action}>Last ned for iOS</button>
            </a>
          )}
          {props.title === "GumGun - Mobilspill" && (
            <a
              href="https://play.google.com/store/apps/details?id=com.stianremvik.GumGun&hl"
            >
              <button className={classes.call_to_action}>Last ned for Android</button>
            </a>
          )}

          {props.title === "BITWaves - Komposisjonsapp for musikk" && (

          <a
            href="https://apps.apple.com/us/app/bitwaves2/id1351848485"
          >
            <button className={classes.call_to_action}>Last ned for iOS</button>
          </a>

          )}
          {props.title === "BITWaves - Komposisjonsapp for musikk" && (
          <a
            href="https://play.google.com/store/apps/details?id=com.stianremvik.bitwaves2&hl=en_US"
          >
            <button className={classes.call_to_action}>Last ned for Android</button>
          </a>
          )}

          {props.title === "Blorgh - Dataspill" && (
            <a
              href="https://stianremvik.itch.io/blorgh"
            >
              <button className={classes.call_to_action}>Spill Blorgh på itch.io</button>
            </a>
          )}

          {props.title === "Sonarc - Eksperimentelt dataspill" && (
            <a
              href="https://stianremvik.itch.io/sonarc"
            >
              <button className={classes.call_to_action}>Download the game for PC/Mac at itch.io</button>
            </a>
          )}

          <div className={classes.preamble}>{props.preamble}</div>
          <div className={classes.text}>{props.text}</div>


          {props.title === "GumGun - Mobilspill" && <GumGun />}

          {props.title === "NODA - Nordnorsk design- og arkitektursenter" && <Noda />}

          {props.title === "DNB - Skjemaer og bruk av designsystem" && <img className={classes.image} src="dnb_responsiv.png" alt="Dnb-skjema vist på mobil, nettbrett og laptop"></img>}



        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Project;
