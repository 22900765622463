import { Route, Routes } from "react-router-dom";
import React, { Fragment } from "react";

import Project from "./pages/Project";
import Home from "./pages/Home";

import projects from "./projects/projects.json";

function App() {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        {projects.map((project) => (
          <Route
            key={project.id}
            path={"/project" + "-" + project.id}
            element={
              <Project
                id={project.id}
                title={project.title}
                preamble={project.preamble}
                text={project.text}
                video={project.video}
                hasAudio={project.hasAudio}
                image={project.image}
                deliverables={project.deliverables}
              />
            }
          />
        ))}
      </Routes>
    </Fragment>
  );
}

export default App;
