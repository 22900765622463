import React, { Fragment } from "react";

import classes from "./GumGun.module.css";

import gumGunSlides from "./GumGun.json";

function GumGun() {
  return (
    <Fragment>
      <div className={classes.header}>Prosess</div>
      {gumGunSlides.map((slides) => (
        <div key={slides.id} className={classes.wrapper}>
          <div className={classes.id}>{slides.id} / {gumGunSlides.length}</div>
          <div className={classes.title}>{slides.title}</div>
          <div className={classes.text}>
            {slides.text}

          </div>
          <img className={classes.image} src={slides.image} alt=""></img>

          {slides.id === 3 && <div className={classes.text}>
            
          <div style={{ fontSize: "1.4rem" }}>Eksempler på problemer i spillet som ble oppdaget under testing:</div><br /><br />
          
          <div style={{ fontWeight: "600" }}>Vanskelig å treffe pauseknappen i en stresset situasjon hvor du må være rask for ikke å bli truffet av fiender.</div><br />Løsning: Stort usynlig, men trykkbart område rundt pauseknappen.<br /><br /><br />
          
          <div style={{ fontWeight: "600" }}>Fiender beveget seg for fort når tyggisbomben ble fyrt av som gjorde det vanskelig å sikte.</div><br />Løsning: Bevegelsen til fiendene ble gjort saktere når bomben lades og spilleren sikter.<br /><br /><br />

          <div style={{ fontWeight: "600" }}>Vanskelig å plukke opp items uten å bli truffet av fiender</div><br />Løsning: Magnet som suger inn items når du kommer nært nok gjorde det mye lettere å få tak i items.<br /><br /><br />

          <div style={{ fontWeight: "600" }}>Ikke tydelig nok at items spawner</div><br />Løsning: Items har fått en animasjon hvor de spretter ut av fiendene og utvides i luften for å tydeligere vise at de dukker opp.<br /><br /><br />

          <div style={{ fontWeight: "600" }}>Vanskelig å se at fiendekuler beveger seg nedover skjermen</div><br />Løsning: Veldig tydelig skyteanimasjon på fiende, rød laserstrek mot spiller når kule starter bevegelse, kulene blinker raskt i rødt og svart, partikkeleffekt som hale bak kulene viser retning i tillegg til å gi mer liv.<br /><br /><br />

          <div style={{ fontWeight: "600" }}>Vanskelig å se items i alt kaoset</div><br />Løsning: Items animeres tydelig ut og inn for å trekke oppmerksomhet og de blinker før de forsvinner for å gjøre det tydelig at de skal til å forsvinne slik at spilleren må ta en rask avgjørelse som vil gi mestringsfølelse hvis de lykkes.<br /><br /><br />

          <div style={{ fontWeight: "600" }}>Ikke tydelig nok at fiender blir truffet</div><br />Løsning: Fiender med mer enn 1 i helse blinker hvit og får en elastisk animasjon når de blir truffet. Animasjonen skaper også mer liv.<br /><br /><br />
          
          </div>}

        </div>
      ))}
    </Fragment>
  );
}

export default GumGun;
